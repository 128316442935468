import { useState, forwardRef, Fragment } from "react";
import { Button, Dialog, AppBar, Grid, Toolbar, IconButton, Paper, Typography, Slide } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RoundEditor from "./RoundEditor";
import ParticipantEditor from "./ParticipantEditor";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsModal(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isDuplicateParticipants, setIsDuplicateParticipants] = useState(false);

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setDialogOpen(true);
                }}
                disabled={props.disabled}
            >
                Setup
            </Button>
            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setDialogOpen(false);
                            }}
                            aria-label="close"
                            disabled={isDuplicateParticipants}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Setup
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ textAlign: "center" }}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={5} sx={{ m: 2, p: 2, bgcolor: "#eee" }}>
                                <RoundEditor rounds={props.rounds} handleChange={props.setRounds} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper elevation={5} sx={{ m: 2, p: 2, bgcolor: "#eee" }}>
                                <ParticipantEditor participants={props.participants} handleChange={props.setParticipants} isDuplicateParticipants={isDuplicateParticipants} setIsDuplicateParticipants={setIsDuplicateParticipants} />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </Fragment>
    );
}
