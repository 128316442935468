import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./HomePage";
import SocketPage from "./SocketPage";
import ReactGA from "react-ga4";

ReactGA.initialize("G-YX1MPNG6FF");

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<HomePage />} />
                <Route path="/wall" exact element={<Navigate to="/" replace />} />
                <Route path="/:roomId/wall" element={<SocketPage page="MODERATOR" />} />
                <Route path="/:roomId/" element={<SocketPage page="PARTICIPANT" />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
