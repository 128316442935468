import { useState, useEffect } from "react";
import logo from "./Images/logo.svg";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function HomePage() {
    const [roomName, setRoomName] = useState("");

    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value);
    };

    let navigate = useNavigate();

    function handleGoButton(event) {
        event.preventDefault();
        if (roomName.trim().length > 0) {
            navigate(`/${roomName.toLowerCase()}`);
            setRoomName("");
        }
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page_title: "Home Page" });
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90vh", flexDirection: "column" }}>
            <img src={logo} alt="Logo" style={{ width: "100%", maxWidth: "1000px" }} />
            <form onSubmit={handleGoButton} style={{ maxWidth: "300px", marginTop: "5vh", display: "flex" }}>
                <TextField sx={{ width: "100%" }} className="homeTextFieldLabel" label={"Room Name"} value={roomName} onChange={handleRoomNameChange} inputProps={{ autoCapitalize: "off" }} />
                <Button type="submit" sx={{ marginLeft: "5px" }} variant="contained">
                    Go
                </Button>
            </form>
        </div>
    );
}
