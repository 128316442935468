import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ParticipantPage from "./ParticipantPage";
import ModeratorPage from "./ModeratorPage";
import io from "socket.io-client";

export default function SocketPage(props) {
    const [socket, setSocket] = useState(null);
    const { roomId } = useParams();

    useEffect(() => {
        if (roomId) {
            const newSocket = io("https://stickyswipe.com:443", {
                query: "room=" + roomId,
            });
            setSocket(newSocket);
            return () => newSocket.close();
        }
    }, [setSocket, roomId]);

    // Warn user before they navigate away
    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    if (props.page === "MODERATOR") {
        return <ModeratorPage socket={socket} />;
    } else {
        return <ParticipantPage socket={socket} />;
    }
}
