import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import CardStatusTable from "./CardStatusTable";
import AllCards from "./AllCards";
import { CardStatuses } from "./CardStatuses";
import SettingsModal from "./SettingsModal";
import ShareModal from "./ShareModal";
import produce from "immer";
import logo from "./Images/logo.svg";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

export default function ModeratorPage({ socket }) {
    const [currentRound, setCurrentRound] = useState(0);
    const [participants, setParticipants] = useState([]); //"Alice", "Bob", "Carol", "Dave", "Eve", "Frank", "George", "Harriet", "Ina", "Juliette", "Katie"
    const [rounds, setRounds] = useState(["Proposed Solution: How would you solve the challenge statement?", "Risks: How could the solution above fail?", "Final Solution: How could you mitigate the risks listed above?"]);
    const [cards, setCards] = useState([]);

    const [nextButtonLabel, setNextButtonLabel] = useState("Start Round 1");

    const randomModeratorId = useRef(Math.random());

    const { roomId } = useParams();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page_title: "Moderator Page" });
    }, []);

    useEffect(() => {
        // Note that currentRound and cardRound values may be confusing....
        // currentRound 0 is the setup round, so curroundRound 1 is the first round (round one)
        // cardRound 0 is the first round (round one)

        function updateCard(cardId, roundId, user, status, content) {
            if (roundId < currentRound - 1) {
                status = CardStatuses.MODIFIED_AFTER_ROUND_ENDED;
            }
            setCards(
                // Using Immer package due to use of deep array (React only does a shallow compare) and need for intelligent update of immutable state object
                // https://immerjs.github.io/immer/example-setstate
                produce((draft) => {
                    draft[cardId].cardRounds[roundId].modifiedBy = user;
                    draft[cardId].cardRounds[roundId].modifiedDate = Date.now();
                    draft[cardId].cardRounds[roundId].status = status;
                    draft[cardId].cardRounds[roundId].content = content;
                })
            );
        }

        if (socket) {
            // When wall page loads, alert any other moderators that there can only be one moderator per room
            socket.emit("roundrobiner message", {
                action: "moderatorPing",
                data: {
                    randomAdminId: randomModeratorId.current,
                },
            });

            socket.on("roundrobiner message", (msg) => {
                if (msg.action === "moderatorPing") {
                    if (msg.data.randomAdminId !== randomModeratorId.current) {
                        alert("Warning!  Another moderator (wall) page for this room has been opened.  Each room can only have one moderator or bad things will happen!");
                    }
                }
                if (currentRound <= rounds.length && currentRound > 0) {
                    switch (msg.action) {
                        case "getUserList":
                            socket.emit("roundrobiner message", {
                                action: "userList",
                                data: {
                                    users: participants,
                                    totalRounds: rounds.length,
                                },
                            });
                            break;
                        case "requestCard":
                            let assignedCard = -1;
                            for (let x = 0; x < cards.length; x++) {
                                if (cards[x].cardRounds[currentRound - 1].assignedTo === msg.data.user) {
                                    assignedCard = x;
                                    break;
                                }
                            }

                            if (assignedCard >= 0 && currentRound <= rounds.length && currentRound > 0) {
                                setCards(
                                    produce((draft) => {
                                        draft[assignedCard].cardRounds[currentRound - 1].status = CardStatuses.INPUT_RECEIVED;
                                    })
                                );
                            }

                            socket.emit("roundrobiner message", {
                                action: "cardData",
                                data: {
                                    user: msg.data.user,
                                    card: assignedCard >= 0 ? cards[assignedCard] : {},
                                    currentRound: currentRound,
                                },
                            });
                            break;
                        case "updateCard": {
                            updateCard(msg.data.cardId, msg.data.roundId, msg.data.user, msg.data.status, msg.data.content);
                            break;
                        }
                        case "requestCurrentServerRound":
                            socket.emit("roundrobiner message", {
                                action: "currentServerRound",
                                data: {
                                    currentRound: currentRound,
                                },
                            });
                            break;
                        default:
                            break;
                    }
                }
            });

            return () => {
                // IMPORTANT - this cleans up the socket event listener to prevent multiple events from firing for each message
                socket.removeAllListeners();
            };
        }
    }, [socket, currentRound, cards, participants, rounds, randomModeratorId]);

    function nextButtonClick() {
        if (currentRound === 0) {
            let allCards = [];

            for (let x = 0; x < participants.length; x++) {
                let card = {};
                card.number = x + 1;
                let cardRounds = [];
                for (let y = 0; y < rounds.length; y++) {
                    cardRounds[y] = {};
                    cardRounds[y].number = y + 1;
                    cardRounds[y].name = rounds[y];
                    cardRounds[y].assignedTo = participants[(y + x) % participants.length];
                    cardRounds[y].status = y === 0 ? CardStatuses.ROUND_STARTED_NO_INPUT : CardStatuses.ROUND_NOT_STARTED;
                    // cardRounds[y].content =
                    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus id lorem ac sapien pulvinar auctor ut sed lorem. Vestibulum urna massa, aliquam ut tristique sit amet, faucibus sit amet augue. Proin nunc augue, ornare at pretium vel, suscipit id sapien. Nunc mollis dui id venenatis efficitur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et luctus elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque cursus elementum velit in rutrum. Morbi ex purus, convallis a mattis ut, ultricies sed neque. In id quam ac ligula mattis efficitur. Donec vel fringilla nunc. Pellentesque sit amet semper ipsum, sit amet ultrices sapien. Phasellus augue nisi, feugiat sit amet sem et, semper tristique magna. Nulla ac pharetra tellus. Donec quis dui nisi. In non augue accumsan, gravida sapien quis, cursus nisi.";
                }
                card.cardRounds = cardRounds;
                allCards[x] = card;
            }
            setCards(allCards);

            // Send list of users to anyone who might be waiting for the game to start
            socket.emit("roundrobiner message", {
                action: "userList",
                data: {
                    users: participants,
                    totalRounds: rounds.length,
                },
            });

            ReactGA.event("activity_started", { room: roomId });
        }

        if (currentRound > 0 && currentRound <= rounds.length) {
            setCards(
                produce((draft) => {
                    draft.forEach((card) => {
                        if (card.cardRounds[currentRound - 1].content && card.cardRounds[currentRound - 1].content.trim().length >= 0) {
                            card.cardRounds[currentRound - 1].status = CardStatuses.SUBMITTED;
                        } else {
                            card.cardRounds[currentRound - 1].status = CardStatuses.ROUND_ENDED_WITHOUT_INPUT;
                        }

                        if (currentRound < rounds.length) {
                            card.cardRounds[currentRound].status = CardStatuses.ROUND_STARTED_NO_INPUT;
                        }
                    });
                })
            );
        }

        if (currentRound + 1 < rounds.length) {
            setNextButtonLabel("Start Round " + (currentRound + 2));
        } else if (currentRound + 1 === rounds.length) {
            setNextButtonLabel("End Last Round");
        } else {
            setNextButtonLabel("(Done)");
        }

        let msg = {
            action: "roundChange",
            data: {
                currentRound: currentRound + 1,
            },
        };
        socket.emit("roundrobiner message", msg);

        // Incrementing the state value at the end because changing it earlier would not take effect until later anyway
        setCurrentRound((prev) => prev + 1);
    }

    return (
        <Fragment>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
                <img src={logo} alt="" style={{ width: "100%", maxWidth: "1000px" }} />
                <div style={{ display: "flex", marginTop: "20px" }}>
                    <h2 style={{ marginTop: "5px", marginBottom: "0px", marginRight: "15px" }}>Room: {roomId.trim().toLowerCase()}</h2>
                    <ShareModal></ShareModal>
                </div>

                <h3 style={{ marginTop: "0px" }}>{currentRound === 0 ? "Not Started" : currentRound <= rounds.length ? "Round " + currentRound : "Done"}</h3>
            </div>

            <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                <SettingsModal rounds={rounds} setRounds={setRounds} participants={participants} setParticipants={setParticipants} disabled={currentRound !== 0}></SettingsModal>
                <Button variant="contained" disabled={nextButtonLabel === "(Done)" || participants.length === 0 || rounds.length === 0} onClick={() => nextButtonClick()}>
                    {nextButtonLabel}
                </Button>
            </div>
            <CardStatusTable cards={cards} />
            <AllCards cards={cards} currentRound={currentRound} rounds={rounds}></AllCards>
        </Fragment>
    );
}
