import { PDFDownloadLink, Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import logo from "./Images/logo.png";

export default function ExportCards(props) {

    const styles = StyleSheet.create({
        titlePage: {
            paddingTop: 300,
            paddingHorizontal: 50,
        },
        body: {
            paddingTop: 50,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        title: {
            fontFamily: "Helvetica-Bold",
            fontSize: 24,
            textAlign: "center",
            textTransform: "uppercase",
        },
        subtitle: {
            fontFamily: "Helvetica-Bold",
            fontSize: 18,
            textAlign: "center",
            marginTop: 30,
        },
        subheading: {
            fontFamily: "Helvetica-Bold",
            margin: 12,
            fontSize: 14,
            textAlign: "justify",
        },
        text: {
            margin: 12,
            fontSize: 14,
            textAlign: "justify",
        },
    });

    Font.registerEmojiSource({
        format: 'png',
        url: 'https://twemoji.maxcdn.com/2/72x72/',
      });

    const MyDoc = () => (
        <Document>
            <Page size="letter" style={styles.titlePage} key="page0">
                <Image src={logo} />
                <Text style={styles.subtitle}>{new Date().toLocaleDateString(undefined, { weekday: "long", year: "numeric", month: "long", day: "numeric" })}</Text>
            </Page>
            {props.cards.map((eachcard, index) => (
                <Page size="letter" style={styles.body} key={"page" + index}>
                    <Text style={styles.title}>Card {eachcard.number}</Text>
                    {eachcard.cardRounds.map((round, roundIndex) => (
                        <View key={roundIndex}>
                            <Text style={styles.subheading}>{props.rounds[roundIndex]}</Text>
                            <Text style={styles.text}>{round.content}</Text>
                        </View>
                    ))}
                </Page>
            ))}
        </Document>
    );

    return (
        props.cards.length > 0 && (
            <PDFDownloadLink document={<MyDoc />} fileName="RoundRobiner.pdf">
                <PictureAsPdfIcon htmlColor="#707071" />
            </PDFDownloadLink>
        )
    );
}
