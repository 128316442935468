import React, { Fragment } from "react";
import { Button, Card as MUICard, CardContent, TextField, Typography } from "@mui/material";
import { CardStatuses } from "./CardStatuses";
import produce from "immer";
import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";

export default function Card(props) {
    const currentParticipantRound = props.currentServerRound;

    const { roomId } = useParams();

    function handleChange(event) {
        props.handleUpdateCard(
            produce((draft) => {
                draft.cardRounds[event.target.name].content = event.target.value;
                draft.cardRounds[event.target.name].dirty = true;
            })
        );
    }

    function modifiedBy(cardRound) {
        if (cardRound.modifiedBy && cardRound.modifiedBy !== cardRound.assignedTo) {
            return (
                <Typography style={{ fontWeight: 300, fontSize: "0.8rem", marginTop: "1rem", fontStyle: "italic", color: "red" }}>
                    Assigned to: {cardRound.assignedTo}
                    <br />
                    Last Modified By: {cardRound.modifiedBy}
                </Typography>
            );
        }
    }

    function allowSubmit() {
        if (props.card.cardRounds && props.card.cardRounds[currentParticipantRound - 1] && props.card.cardRounds[currentParticipantRound - 1].content && props.card.cardRounds[currentParticipantRound - 1].content.trim().length > 0) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <Fragment>
            <MUICard>
                <CardContent>
                    <h2 style={{ marginBlockStart: "0.4em" }}>CARD {props.card.number}</h2>
                    {props.card.cardRounds.map(
                        (cardRound, index) =>
                            cardRound.number <= currentParticipantRound && (
                                <div key={"roundContent" + index} style={{ marginBottom: "2rem" }}>
                                    <Typography sx={{ lineHeight: 1.2, marginBottom: "1rem", fontWeight: "bold" }}>{cardRound.name}</Typography>
                                    {props.readOnly ? (
                                        <>
                                            <Typography style={{ whiteSpace: "pre-line" }}>{cardRound.content}</Typography>
                                            {modifiedBy(cardRound)}
                                        </>
                                    ) : (
                                        <TextField placeholder="Please type your answer here" sx={{ width: "100%" }} multiline hiddenLabel value={cardRound.content} name={index.toString()} onChange={handleChange} />
                                    )}
                                </div>
                            )
                    )}
                </CardContent>
            </MUICard>
            {!props.readOnly && (
                <div style={{ display: "flex", justifyContent: "center", gap: 5, marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.card.cardRounds.forEach((cardRound) => {
                                if (cardRound.dirty) {
                                    let msg = {
                                        action: "updateCard",
                                        data: {
                                            cardId: props.card.number - 1,
                                            roundId: cardRound.number - 1,
                                            user: props.user,
                                            status: CardStatuses.SUBMITTED,
                                            content: cardRound.content,
                                        },
                                    };
                                    props.socket.emit("roundrobiner message", msg);

                                    ReactGA.event("card_round_submitted", { room: roomId });
                                }
                            });

                            props.handleUpdateCard(null);
                            props.handleCardSubmitted(currentParticipantRound);
                        }}
                        disabled={!allowSubmit()}
                    >
                        SUBMIT
                    </Button>
                </div>
            )}
        </Fragment>
    );
}
