import { useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { Alert, Button, Divider, List, ListItem, ListItemIcon, ListItemText, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import produce from "immer";

export default function RoundEditor(props) {
    const [newRoundName, setNewRoundName] = useState("");
    const [editingRoundIndex, setEditingRoundIndex] = useState();

    const handleNewRoundNameChange = (event) => {
        setNewRoundName(event.target.value);
    };

    function handleDelete(index) {
        props.handleChange(
            produce((draft) => {
                draft.splice(index, 1);
            })
        );
    }

    function handleEditRound(index, val) {
        props.handleChange(
            produce((draft) => {
                draft[index] = val;
            })
        );
    }

    function handleAddRound(event) {
        event.preventDefault();
        if (newRoundName.trim().length > 0) {
            props.handleChange(
                produce((draft) => {
                    draft.push(newRoundName);
                })
            );
            setNewRoundName("");
        }
    }

    const onDrop = ({ removedIndex, addedIndex }) => {
        props.handleChange((prev) => arrayMove(prev, removedIndex, addedIndex));
    };

    return (
        <div>
            <h1>Rounds</h1>
            {props.rounds.length === 0 && <Alert severity="warning">Please add rounds</Alert>}
            {props.rounds.length > 0 && (
                <List sx={{ bgcolor: "background.paper" }}>
                    <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                        {props.rounds.map((round, index) => (
                            <Draggable key={index}>
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => {
                                                handleDelete(index);
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemIcon className="drag-handle" sx={{ cursor: "move" }}>
                                        <DragHandleIcon />
                                    </ListItemIcon>
                                    {editingRoundIndex === index ? (
                                        <TextField
                                            autoFocus
                                            value={round}
                                            style={{ width: "100%" }}
                                            onChange={(event) => handleEditRound(index, event.target.value)}
                                            onBlur={() => setEditingRoundIndex(-1)}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") event.target.blur();
                                            }}
                                        />
                                    ) : (
                                        <ListItemText
                                            primary={round}
                                            onClick={() => {
                                                setEditingRoundIndex(index);
                                            }}
                                        />
                                    )}
                                </ListItem>
                                {index + 1 < props.rounds.length && <Divider component="li" />}
                            </Draggable>
                        ))}
                    </Container>
                </List>
            )}
            <form onSubmit={handleAddRound} style={{ marginTop: "5vh", display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>
                <TextField label={"Round Name"} value={newRoundName} onChange={handleNewRoundNameChange} style={{ width: "100%" }} />
                <Button type="submit" sx={{ marginLeft: "5px" }} variant="contained">
                    Add Round
                </Button>
            </form>
        </div>
    );
}
