import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import iconBlueInProgress from "./Images/blue-in-progress.svg";
import iconGreenCheck from "./Images/green-check.svg";
import iconRedAlert from "./Images/red-alert.svg";
import iconRedCheck from "./Images/red-check.svg";
import iconYellowQuestion from "./Images/yellow-question.svg";
import { CardStatuses } from "./CardStatuses";

export default function CardStatusTable(props) {
    function getStatusIcon(status) {
        switch (status) {
            case CardStatuses.ROUND_STARTED_NO_INPUT:
                return iconYellowQuestion;
            case CardStatuses.INPUT_RECEIVED:
                return iconBlueInProgress;
            case CardStatuses.SUBMITTED:
                return iconGreenCheck;
            case CardStatuses.ROUND_ENDED_WITHOUT_INPUT:
                return iconRedAlert;
            case CardStatuses.MODIFIED_AFTER_ROUND_ENDED:
                return iconRedCheck;
            default:
                return iconYellowQuestion;
        }
    }

    if (props.cards[0]) {
        return (
            <>
                <h1>CARD STATUS</h1>
                <TableContainer component={Paper} style={{ marginBottom: 50, maxHeight: "100vh", boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Table aria-label="card status table" stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {props.cards[0].cardRounds.map((round, index) => (
                                    <TableCell key={index} sx={{fontWeight: "bold"}}>
                                        Round {index + 1}
                                        <Tooltip title={round.name}>
                                            <IconButton>
                                                <InfoIcon htmlColor="#999" sx={{ width: "20px", height: "20px", marginBottom: "2px"}} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.cards.map((card, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{fontWeight: "bold"}}>Card {card.number}</TableCell>
                                    {card.cardRounds.map((cardRound, index) =>
                                        cardRound.status !== CardStatuses.ROUND_NOT_STARTED ? (
                                            <TableCell key={index}>
                                                <Tooltip title={cardRound.status}>
                                                    <img src={getStatusIcon(cardRound.status)} alt={cardRound.status} style={{ height: "1.2rem", verticalAlign: "middle", marginRight: 4, paddingBottom:3 }} />
                                                </Tooltip>
                                                {cardRound.assignedTo}
                                            </TableCell>
                                        ) : (
                                            <TableCell key={index} />
                                        )
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    } else {
        return (
            <>
                <h1>Card Status</h1>
                <h3>Not Started</h3>
            </>
        );
    }
}
