import { useState, forwardRef, Fragment } from "react";
import { useParams } from "react-router-dom";
import { AppBar, Dialog, IconButton, Slide, Snackbar, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QRCode from "react-qr-code";
import logo from "./Images/logo.svg";
import copyToClipboard from "copy-to-clipboard";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareModal(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const { roomId } = useParams();

    return (
        <Fragment>
            <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                    setDialogOpen(true);
                }}
                aria-label="close"
            >
                <QrCodeScannerIcon htmlColor="#707071" sx={{ height: "20px" }} />
            </IconButton>
            <Dialog
                fullScreen
                maxWidth="sm"
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setDialogOpen(false);
                            }}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Share
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "50px" }}>
                    <img src={logo} alt="" style={{ width: "100%", maxWidth: "1000px" }} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 className="shareUrl" style={{ fontSize: "50px", maxWidth: "100%" }}>
                            roundrobiner.com/{roomId}
                        </h2>
                        <div style={{ marginLeft: "20px" }}>
                            <IconButton
                                onClick={() => {
                                    copyToClipboard("https://roundrobiner.com/" + roomId);
                                    setSnackbarOpen(true);
                                }}
                                aria-label="copy"
                            >
                                <ContentCopyIcon htmlColor="#707071" />
                            </IconButton>
                        </div>
                    </div>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2000}
                        onClose={() => {
                            setSnackbarOpen(false);
                        }}
                        message="Link copied to clipboard"
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    />
                    <QRCode value={"https://roundrobiner.com/" + roomId} style={{ height: "50vh", maxWidth: "100%", width: "100%", margin: "40px" }} viewBox={`0 0 256 256`} />
                </div>
            </Dialog>
        </Fragment>
    );
}
