import { useState, useEffect, Fragment } from "react";
import { Alert, Button, Divider, List, ListItem, ListItemText, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import produce from "immer";

export default function ParticipantEditor(props) {
    const [newParticipantName, setNewParticipantName] = useState("");

    const handleNewParticipantNameChange = (event) => {
        setNewParticipantName(event.target.value);
    };

    const [editingParticipantIndex, setEditingParticipantIndex] = useState(-1);

    let memoParticipantList = props.participants;
    let memoSetIsDuplicateParticipants = props.setIsDuplicateParticipants;

    useEffect(() => {
        // Check for duplicates when partipant list changes
        const uniqueArray = new Set(memoParticipantList.map((e) => e.trim().toLowerCase()));
        memoSetIsDuplicateParticipants(uniqueArray.size !== memoParticipantList.length);
    }, [memoParticipantList, memoSetIsDuplicateParticipants]);

    function handleDelete(index) {
        props.handleChange(
            produce((draft) => {
                draft.splice(index, 1);
            })
        );

        setEditingParticipantIndex(-1);
    }

    function handleEditParticipant(index, val) {
        props.handleChange(
            produce((draft) => {
                draft[index] = val;
            })
        );
    }

    function handleAddParticipant(event) {
        event.preventDefault();
        const pn = newParticipantName.trim();
        if (pn.length > 0) {
                props.handleChange(
                    produce((draft) => {
                        draft.push(pn);
                        draft.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
                    })
                );
                setNewParticipantName("");
        }
    }

    function editBlur(index, val) {
        props.handleChange(
            produce((draft) => {
                draft[index] = val.trim();
                draft.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            })
        );

        setEditingParticipantIndex(-1);
    }

    return (
        <div>
            <h1>Participants</h1>
            {props.participants.length === 0 && <Alert severity="warning">Please add participants</Alert>}
            {props.isDuplicateParticipants && editingParticipantIndex === -1 && (
                <Alert severity="warning" sx={{ marginBottom: "10px" }}>
                    Please remove duplicate participants
                </Alert>
            )}
            {props.participants.length > 0 && (
                <List sx={{ bgcolor: "background.paper" }}>
                    {props.participants.map((participant, index) => (
                        <Fragment key={index}>
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            handleDelete(index);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                {editingParticipantIndex === index ? (
                                    <TextField
                                        autoFocus
                                        value={participant}
                                        style={{ width: "100%" }}
                                        onChange={(event) => handleEditParticipant(index, event.target.value)}
                                        onBlur={(event) => editBlur(index, event.target.value)}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") event.target.blur();
                                        }}
                                    />
                                ) : (
                                    <ListItemText
                                        primary={participant}
                                        onClick={() => {
                                            setEditingParticipantIndex(index);
                                        }}
                                    />
                                )}
                            </ListItem>
                            {index + 1 < props.participants.length && <Divider component="li" />}
                        </Fragment>
                    ))}
                </List>
            )}
            <form onSubmit={handleAddParticipant} style={{ marginTop: "5vh", display: "flex", justifyContent: "center" }}>
                <TextField label={"Participant Name"} value={newParticipantName} onChange={handleNewParticipantNameChange} />
                <Button type="submit" sx={{ marginLeft: "5px", lineHeight: 1.2 }} variant="contained">
                    Add Participant
                </Button>
            </form>
        </div>
    );
}
