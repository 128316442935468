import Card from "./Card";
import ExportCards from "./ExportCards";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function AllCards(props) {
    let cardWidth = "80vw";

    const theme = useTheme();
    const isMdSize = useMediaQuery(theme.breakpoints.up("md"));
    const isLgSize = useMediaQuery(theme.breakpoints.up("lg"));

    if (isMdSize) {
        if (props.cards && props.cards.length > 1) {
            cardWidth = "45vw";
        }
        else if (props.cards && props.cards.length === 1) {
            cardWidth = "calc(100vw-50px)"
        }
    }

    if (isLgSize) {
        if (props.cards && props.cards.length <= 5) {
            cardWidth = `calc(${100 / props.cards.length}vw - ${(10 * (props.cards.length - 1)) / props.cards.length}px - ${50 / props.cards.length}px)`;
        } else {
            cardWidth = "18vw";
        }
    }

    if (props.cards && props.cards[0]) {
        return (
            <>
                <h1 style={{ display: "inline-block", marginRight: "10px" }}>ALL CARDS</h1>
                <ExportCards cards={props.cards} rounds={props.rounds}></ExportCards>
                <div style={{ width: "100%", display: "flex", gap: "10px", overflowX: "scroll", paddingBottom: "5px" }}>
                    {props.cards.map((eachcard, index) => (
                        <div key={"cardContainer" + index}>
                            <div style={{ width: cardWidth }}>
                                <Card card={eachcard} readOnly currentServerRound={props.currentRound} />
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    } else {
        return (
            <>
                <h1>All Cards</h1>
                <h3>Not Started</h3>
            </>
        );
    }
}
