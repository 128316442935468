import { Fragment, useState, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import logo from "./Images/logo.svg";
import Card from "./Card";
import ReactGA from "react-ga4";

export default function ParticipantPage({ socket }) {
    const [userList, setUserList] = useState([]);
    const [currentUser, setCurrentUser] = useState("");
    const [currentCard, setCurrentCard] = useState();
    const [currentServerRound, setCurrentServerRound] = useState(0);
    const [lastRoundSubmitted, setLastRoundSubmitted] = useState();
    const [totalRounds, setTotalRounds] = useState();
    const [firstLoad, setFirstLoad] = useState(true);

    const [timeOfLastRoundUpdate, setTimeOfLastRoundUpdate] = useState(Date.now());
    const [timeOfLastUserListRequest, setTimeOfLastUserListRequest] = useState(Date.now());

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page_title: "Participant Page" });
    }, []);

    useEffect(() => {
        const POLL_INTERVAL = 5000;

        if (socket) {
            socket.on("roundrobiner message", (msg) => {
                switch (msg.action) {
                    case "cardData":
                        if (msg.data.user === currentUser) {
                            setCurrentCard(msg.data.card);
                            setCurrentServerRound(msg.data.currentRound);
                        }
                        break;
                    case "userList":
                        setUserList(msg.data.users);
                        setTotalRounds(msg.data.totalRounds);
                        break;
                    case "roundChange":
                    case "currentServerRound":
                        setCurrentServerRound(msg.data.currentRound);
                        setTimeOfLastRoundUpdate(Date.now());
                        break;
                    case "getUserList":
                        setTimeOfLastUserListRequest(Date.now());
                        break;
                    default:
                        break;
                }
            });

            function requestUserList() {
                let msg = {
                    action: "getUserList",
                };
                socket.emit("roundrobiner message", msg);
            }

            if (firstLoad) {
                requestUserList();
                setFirstLoad(false);
            }

            // Poll server for the user list (round 1 started) until a response is received
            let checkForUserListTimer = setInterval(() => {
                if (userList.length === 0) {
                    let timeSinceLastUserListRequest = Date.now() - timeOfLastUserListRequest;

                    // Only poll the server if no updates (other participants may have already asked, so no need to ask again and overload server)
                    if (timeSinceLastUserListRequest >= POLL_INTERVAL) {
                        requestUserList();
                    }
                } else {
                    clearInterval(checkForUserListTimer);
                }
            }, POLL_INTERVAL);

            // After submitting card, poll for round changes in case the push message is missed
            let checkForRoundChange = setInterval(() => {
                if (lastRoundSubmitted && currentServerRound === lastRoundSubmitted && currentServerRound < totalRounds) {
                    let timeSinceLastRoundUpdate = Date.now() - timeOfLastRoundUpdate;

                    // Only poll the server if no updates (other participants may have already asked, so no need to ask again and overload server)
                    if (timeSinceLastRoundUpdate >= POLL_INTERVAL) {
                        let msg = {
                            action: "requestCurrentServerRound",
                        };
                        socket.emit("roundrobiner message", msg);
                    }
                } else {
                    clearInterval(checkForRoundChange);
                }
            }, POLL_INTERVAL);

            return () => {
                // IMPORTANT - this cleans up the socket event listener to prevent multiple events from firing for each message
                socket.removeAllListeners();

                // IMPORTANT - this cleans up the timer to prevent multiple duplicate timers from running
                clearInterval(checkForUserListTimer);
                clearInterval(checkForRoundChange);
            };
        }
    }, [socket, currentUser, userList.length, currentServerRound, lastRoundSubmitted, totalRounds, timeOfLastRoundUpdate, timeOfLastUserListRequest, firstLoad]);

    function requestCard() {
        let msg = {
            action: "requestCard",
            data: {
                user: currentUser,
            },
        };
        socket.emit("roundrobiner message", msg);
    }

    return (
        <Fragment>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
                <img src={logo} alt="" style={{ width: "80%", maxWidth: "1000px", marginBottom: "20px" }} />
                {currentUser.length > 0 && (
                    <div className="currentUser" style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", marginTop: 20, marginBottom: "1vw" }}>
                        <AccountBoxIcon sx={{ color: "#707071", marginBottom: "1px" }} /> <span style={{ color: "#707071" }}>{currentUser}</span>
                    </div>
                )}
                {userList.length === 0 && (
                    <Fragment>
                        <h2>Please wait for the moderator to start this activity...</h2>
                        <CircularProgress />
                    </Fragment>
                )}
                {/* {currentCard && currentCard.cardRounds && <h2>ROUND {currentRound}</h2>} */}
                {currentUser === "" && userList.length > 0 && <h3>Select Your Name:</h3>}
                <div className="userList" style={{ display: "flex", gap: "5px" }}>
                    {currentUser === "" &&
                        userList.map((u, index) => (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setCurrentUser(u);
                                }}
                                key={index}
                            >
                                {u}
                            </Button>
                        ))}
                </div>

                {lastRoundSubmitted && currentServerRound === lastRoundSubmitted && currentServerRound < totalRounds && (
                    <Fragment>
                        <h2>Please wait for the next round to begin...</h2>
                        <CircularProgress />
                    </Fragment>
                )}

                {((lastRoundSubmitted && currentServerRound === lastRoundSubmitted && currentServerRound >= totalRounds) || currentServerRound > totalRounds) && <h2>All Done. Thanks!</h2>}

                {currentUser.length > 0 && !currentCard && (!lastRoundSubmitted || currentServerRound > lastRoundSubmitted) && currentServerRound <= totalRounds && (
                    <Button
                        variant="contained"
                        sx={{ marginTop: "5vh" }}
                        onClick={() => {
                            requestCard();
                        }}
                    >
                        Get Card
                    </Button>
                )}
            </div>
            {currentCard && currentCard.cardRounds && <Card card={currentCard} socket={socket} user={currentUser} handleUpdateCard={setCurrentCard} currentServerRound={currentServerRound} handleCardSubmitted={setLastRoundSubmitted} />}
        </Fragment>
    );
}
